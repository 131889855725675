.FiltersForm {
  padding: 0 15px;
  background: #2B2B2B;
  border-radius: 4px;
  moz-border-radius: 4px;
  webkit-border-radius: 4px;
  border: 1px solid #3B3B3B; }
  .FiltersForm .row .input-group {
    margin: 1rem 0; }
  .FiltersForm .row.btn-row {
    background: #454545;
    padding: 15px;
    text-align: center; }

@media print {
  .printForm {
    color: #000 !important;
    border: none !important; } }
