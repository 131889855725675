.TransactionsTable table tbody tr {
  cursor: pointer; }
  .TransactionsTable table tbody tr td.ok {
    border-left: 8px solid #38824E; }
  .TransactionsTable table tbody tr td.pending {
    border-left: 8px solid #DB7A25; }
  .TransactionsTable table tbody tr td.cancelled, .TransactionsTable table tbody tr td.error {
    border-left: 8px solid #8F213F; }
  .TransactionsTable table tbody tr td > div {
    font-weight: bold; }
  .TransactionsTable table tbody tr:hover td.ok {
    border-left: 8px solid #337748; }
  .TransactionsTable table tbody tr:hover td.pending {
    border-left: 8px solid #cf7322; }
  .TransactionsTable table tbody tr:hover td.cancelled, .TransactionsTable table tbody tr:hover td.error {
    border-left: 8px solid #831e3a; }
  .TransactionsTable table tbody tr.selected td {
    border-top: 1px solid #646464;
    border-bottom: 1px solid #646464; }
    .TransactionsTable table tbody tr.selected td:first-child {
      border-left: 8px solid #646464; }
    .TransactionsTable table tbody tr.selected td:last-child {
      border-right: 1px solid #646464; }

@media print {
  .TransactionsTable table {
    color: #000 !important; }
    .TransactionsTable table thead th {
      vertical-align: middle;
      border-bottom: 1px #171717; }
    .TransactionsTable table tbody tr {
      border: none !important; }
      .TransactionsTable table tbody tr td {
        border: 0.25 darkgray; } }
