@import url("https://fonts.googleapis.com/css?family=Rubik&display=swap");
html body {
  font-family: 'Rubik', sans-serif; }
  html body .capitalize {
    text-transform: capitalize; }
  html body .warning-message {
    color: #A7191E; }
    html body .warning-message svg {
      color: #A7191E; }
  html body .btn-group .btn {
    box-shadow: none !important; }
  html body .onlyPrint {
    display: none; }
  @media print {
    html body .noPrint {
      display: none; }
    html body .onlyPrint {
      display: block; } }
