.FileUploader .dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out; }
  .FileUploader .dropzone p {
    color: #171616; }
  .FileUploader .dropzone:focus {
    border-color: #2196F3; }

.FileUploader aside {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center; }
  .FileUploader aside button {
    margin-top: 2rem; }
    .FileUploader aside button svg {
      margin-right: 1rem; }
