.Balance .card h1 {
  display: inline-block;
  width: auto;
  font-size: 6rem !important;
  line-height: 1.0; }
  .Balance .card h1 small {
    display: block;
    font-size: 2.0rem; }

.Balance .AskPayment {
  padding-left: 1rem !important; }
