.PageTitle {
  margin-bottom: 2rem;
  border-bottom: 1px solid #ACACAC; }
  .PageTitle h1, .PageTitle h2, .PageTitle h3, .PageTitle h4, .PageTitle h5, .PageTitle h6 {
    background: #212121;
    background: linear-gradient(90deg, #212121 0%, rgba(33, 33, 33, 0) 100%);
    margin-bottom: 0;
    padding: 0.5rem; }
    .PageTitle h1 .text, .PageTitle h2 .text, .PageTitle h3 .text, .PageTitle h4 .text, .PageTitle h5 .text, .PageTitle h6 .text {
      margin-left: 0.5rem; }
  .PageTitle h1 .icon svg {
    width: 3rem;
    height: 3rem; }
  .PageTitle h2 .icon svg {
    width: 2.5rem;
    height: 2.5rem; }
  .PageTitle h3 .icon svg {
    width: 2rem;
    height: 2rem; }
  .PageTitle h4 .icon svg {
    width: 1.75rem;
    height: 1.75rem; }
  .PageTitle h5 .icon svg {
    width: 1.5rem;
    height: 1.5rem; }
  .PageTitle h6 .icon svg {
    width: 1.25rem;
    height: 1.25rem; }
  @media print {
    .PageTitle h1, .PageTitle h2, .PageTitle h3, .PageTitle h4, .PageTitle h5, .PageTitle h6 {
      background: #fff !important;
      color: #171616 !important; } }
