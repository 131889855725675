@media print {
  .TransactionsResume .fiveColPrint {
    max-width: 20% !important; }
  .TransactionsResume .sixColPrint {
    max-width: 16.6% !important; }
  .TransactionsResume .colPrint h3 {
    font-size: 20px; }
  .TransactionsResume .colPrint .Widget .header {
    border-bottom: none;
    padding-bottom: 0; } }
