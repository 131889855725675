.AskPaymentModal .modal-content {
  color: #FFFFFF;
  background: #171717;
  border: 1px solid #656565; }
  .AskPaymentModal .modal-content .modal-header {
    border-bottom-color: #656565; }
    .AskPaymentModal .modal-content .modal-header .close {
      color: #FFFFFF; }
  .AskPaymentModal .modal-content .modal-body hr {
    border-color: #656565; }
  .AskPaymentModal .modal-content .modal-body .effective_amount {
    font-size: 3rem;
    font-weight: bold; }
  .AskPaymentModal .modal-content .modal-body .badges {
    text-align: right; }
    .AskPaymentModal .modal-content .modal-body .badges .badge {
      font-size: 0.95rem;
      font-weight: normal; }
      .AskPaymentModal .modal-content .modal-body .badges .badge.ok {
        background: #38824E; }
      .AskPaymentModal .modal-content .modal-body .badges .badge.pending {
        background: #DB7A25; }
      .AskPaymentModal .modal-content .modal-body .badges .badge.cancelled {
        background: #8F213F; }
      .AskPaymentModal .modal-content .modal-body .badges .badge.error {
        background: #8F213F; }
      .AskPaymentModal .modal-content .modal-body .badges .badge svg {
        width: 1.5rem !important;
        height: 1.5rem !important;
        padding-right: 5px; }
  .AskPaymentModal .modal-content .modal-body label {
    display: block;
    font-weight: bold;
    margin-bottom: 0; }
  .AskPaymentModal .modal-content .modal-body .customer-contract {
    padding: 0; }
  .AskPaymentModal .modal-content .modal-footer {
    border-top-color: #656565; }
    .AskPaymentModal .modal-content .modal-footer .input-group input {
      height: 39px; }
    .AskPaymentModal .modal-content .modal-footer .ErrorMessage {
      margin: 0 auto; }
      .AskPaymentModal .modal-content .modal-footer .ErrorMessage svg {
        width: 2rem !important;
        height: 2rem !important; }
      .AskPaymentModal .modal-content .modal-footer .ErrorMessage h3 {
        font-size: 1.2rem; }
