.Menu ul.navbar-nav li.nav-item {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0 1rem; }
  .Menu ul.navbar-nav li.nav-item > a {
    color: rgba(255, 255, 255, 0.5); }
    .Menu ul.navbar-nav li.nav-item > a svg {
      color: #AAA !important; }
    .Menu ul.navbar-nav li.nav-item > a span {
      padding-left: 5px; }
    .Menu ul.navbar-nav li.nav-item > a:hover {
      color: rgba(255, 255, 255, 0.75);
      text-decoration: none; }
    .Menu ul.navbar-nav li.nav-item > a.active {
      color: white; }
  .Menu ul.navbar-nav li.nav-item .dropdown-menu button {
    background: transparent;
    padding-left: 0;
    padding-right: 0; }
    .Menu ul.navbar-nav li.nav-item .dropdown-menu button a {
      color: rgba(0, 0, 0, 0.5) !important; }
      .Menu ul.navbar-nav li.nav-item .dropdown-menu button a span {
        color: #171717;
        padding-left: 5px; }
    .Menu ul.navbar-nav li.nav-item .dropdown-menu button.active {
      color: black !important; }

@media screen and (max-width: 767px) {
  .Menu {
    margin-top: 1.2rem !important; } }
