.Widget {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 1rem; }
  .Widget .header {
    flex: 1;
    border-bottom: 1px solid #676767;
    padding: 1rem 0;
    background: #121212; }
    .Widget .header h3 {
      font-weight: bold;
      font-size: 2rem; }
  .Widget .body {
    flex: 1;
    padding: 1rem 0;
    background: #232323; }
    .Widget .body span {
      flex: 1;
      font-style: italic; }
    .Widget .body .action {
      margin-top: 10px; }
