.StyledTable .top-actions input {
  border-top: 1px solid transparent;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 40px; }

.StyledTable .top-actions button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 39px;
  margin-bottom: 1px; }

.StyledTable table {
  border-width: 0 !important;
  border-collapse: separate;
  border-spacing: 0 0.5rem; }
  .StyledTable table th {
    text-align: center;
    background: #000000;
    border-color: #171717;
    border-bottom-width: 1px;
    border-left-width: 0; }
    .StyledTable table th:first-child {
      border-left-width: 1px;
      border-radius: 4px 0 0 0; }
    .StyledTable table th:last-child {
      border-radius: 0 4px 0 0; }
  .StyledTable table tbody {
    border-width: 1px; }
    .StyledTable table tbody tr td {
      vertical-align: middle;
      padding: 5px 0;
      font-size: 1.0rem;
      line-height: 1.2rem;
      border: none;
      border-top: 1px solid #444444;
      border-bottom: 1px solid #444444;
      text-align: center; }
      .StyledTable table tbody tr td.with-left-icon {
        position: relative;
        overflow: hidden; }
        .StyledTable table tbody tr td.with-left-icon svg.left-icon {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 2px;
          opacity: 0.05;
          z-index: 0; }
      .StyledTable table tbody tr td:first-child {
        border-left: 1px solid #444444; }
      .StyledTable table tbody tr td:last-child {
        border-right: 1px solid #444444; }
      .StyledTable table tbody tr td:nth-child(odd) {
        background: #121212; }
      .StyledTable table tbody tr td:nth-child(even) {
        background: #030303; }
    .StyledTable table tbody tr:hover td:nth-child(odd) {
      background: #232323; }
    .StyledTable table tbody tr:hover td:nth-child(even) {
      background: #141414; }

.StyledTable .react-bootstrap-table-pagination {
  text-align: right !important; }
  .StyledTable .react-bootstrap-table-pagination .react-bootstrap-table-pagination-list li a.page-link {
    color: #232323; }
  .StyledTable .react-bootstrap-table-pagination .react-bootstrap-table-pagination-list li.active a.page-link {
    color: #FFFFFF;
    background-color: #232323;
    border-color: #232323; }

@media print {
  .StyledTable .react-bootstrap-table th {
    color: #000 !important; }
  .StyledTable .react-bootstrap-table tr {
    color: #000 !important; }
  .StyledTable .react-bootstrap-table .order-4 {
    display: none; } }
