.BatchModal .modal-content {
  color: #FFFFFF;
  background: #171717;
  border: 1px solid #656565; }
  .BatchModal .modal-content .modal-header {
    border-bottom-color: #656565; }
    .BatchModal .modal-content .modal-header .close {
      color: #FFFFFF; }
  .BatchModal .modal-content .modal-body hr {
    border-color: #656565; }
  .BatchModal .modal-content .modal-footer {
    border-top-color: #656565; }
    .BatchModal .modal-content .modal-footer .input-group input {
      height: 39px; }
