.ReportResult .PageTitle {
  margin-bottom: 0; }

.ReportResult .reportCard {
  margin-bottom: 50px;
  min-height: 350px; }
  .ReportResult .reportCard svg {
    min-height: 300px; }

@media print {
  .ReportResult .totalsPrint {
    color: #000;
    min-width: 33.3% !important; }
    .ReportResult .totalsPrint h3 {
      font-size: 20px; }
    .ReportResult .totalsPrint .Widget .header {
      border-bottom: none;
      padding-bottom: 0; }
  .ReportResult .payReimbprint {
    color: #000;
    min-width: 50% !important; }
  .ReportResult .reportPrintTable {
    min-width: 100%; }
  .ReportResult .reportCard {
    border: none;
    min-height: 50px !important;
    break-inside: avoid; }
  .ReportResult svg {
    min-height: 50px; } }
