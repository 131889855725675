.TransactionsListing table tbody tr td.ok:nth-child(odd) {
  background: #38824E; }

.TransactionsListing table tbody tr td.ok:nth-child(even) {
  background: #337748; }

.TransactionsListing table tbody tr td.pending:nth-child(odd) {
  background: #DB7A25; }

.TransactionsListing table tbody tr td.pending:nth-child(even) {
  background: #cf7322; }

.TransactionsListing table tbody tr td.cancelled:nth-child(odd), .TransactionsListing table tbody tr td.error:nth-child(odd) {
  background: #8F213F; }

.TransactionsListing table tbody tr td.cancelled:nth-child(even), .TransactionsListing table tbody tr td.error:nth-child(even) {
  background: #831e3a; }

.TransactionsListing table tbody tr td > div {
  font-weight: bold; }

.TransactionsListing table tbody tr:hover td.ok:nth-child(odd) {
  background: #337748; }

.TransactionsListing table tbody tr:hover td.ok:nth-child(even) {
  background: #2f6d41; }

.TransactionsListing table tbody tr:hover td.pending:nth-child(odd) {
  background: #cf7322; }

.TransactionsListing table tbody tr:hover td.pending:nth-child(even) {
  background: #c16b20; }

.TransactionsListing table tbody tr:hover td.cancelled:nth-child(odd), .TransactionsListing table tbody tr:hover td.error:nth-child(odd) {
  background: #831e3a; }

.TransactionsListing table tbody tr:hover td.cancelled:nth-child(even), .TransactionsListing table tbody tr:hover td.error:nth-child(even) {
  background: #761b34; }
