.AccountRatesForm .card-type-wrapper {
  border: 1px dotted #555;
  border-bottom: none; }
  .AccountRatesForm .card-type-wrapper .card-type-title {
    cursor: pointer;
    background: #000;
    padding: 5px; }
    .AccountRatesForm .card-type-wrapper .card-type-title .text {
      text-transform: capitalize;
      padding-left: 5px; }
  .AccountRatesForm .card-type-wrapper .card-type-content {
    display: none;
    background: #232323;
    padding: 5px; }
  .AccountRatesForm .card-type-wrapper:last-child {
    border-bottom: 1px dotted #555; }
  .AccountRatesForm .card-type-wrapper.unused .card-type-title {
    background: #151515;
    font-style: italic; }
  .AccountRatesForm .card-type-wrapper.active .card-type-title {
    background: #17a2b8; }
  .AccountRatesForm .card-type-wrapper.active .card-type-content {
    display: block; }
